import NextImage, { ImageProps } from "next/image"

const CloudFrontUrl =
  process.env.NEXT_PUBLIC_CLOUDFRONT_IMAGE_URL ||
  "https://d2t3pznu9bnvo1.cloudfront.net"

export const lambdaLoader = ({
  src,
  width,
  quality,
}: {
  src: string
  width: number
  quality: number
}) => {
  const [, bucket] = src.match(/https:\/\/([\w-]+)/)
  const [, key] = src.match(/amazonaws\.com\/(.*)/)
  const imageReq = JSON.stringify({
    bucket,
    key,
    edits: {
      resize: {
        width,
      },
    },
    quality,
  })

  const rq =
    typeof window === "undefined"
      ? Buffer.from(imageReq).toString("base64")
      : btoa(imageReq)
  const root =
    bucket === "bunuba-media-prod"
      ? "https://d3tffswkw0w6aa.cloudfront.net"
      : CloudFrontUrl
  return `${root}/${rq}`
}

export const Image = ({
  src,
  ...props
}: { src: string } & Omit<ImageProps, "src">) => {
  if (!src) {
    return null
  }
  // if (!src?.match(/^\//)) {
  //   return <NextImage loader={lambdaLoader} src={src} {...props} />
  // }
  return <NextImage src={src} {...props} unoptimized />
}

export default Image
